import React, { Component, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import makeCancellablePromise from 'make-cancellable-promise';
import { collection, getDocs, doc, onSnapshot, query, where } from 'firebase/firestore';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { toast } from 'react-toastify';
import { auth, db } from './../helper/firebase';
import LoadingPage from './../components/LoadingPage/LoadingPage';

const Login = React.lazy(() => import('./Login/Login')),
// const Login = React.lazy(() => {
//         return new Promise(resolve => {
//           setTimeout(() => resolve(import('./Login/Login')), 300000);
//         });
//       }),
      Dashboard = React.lazy(() => import('./Dashboard/Dashboard'));

class App extends Component{
  constructor(){
    super();
    
    this.state = {
      signIn: '',
      email: ''
    }

    this.cancellableCheck = '';
    this.cancellableLogout = '';

    this.logOut = this.logOut.bind(this);
  }

  componentDidMount(){
    onAuthStateChanged(auth, (user) => {
      if(user){
        this.cancellableCheck = makeCancellablePromise(
          getDocs(
            query(
              collection(db, 'user', user.uid, 'userPrivilege'), where('userPrivilegeApp', '==', 'enstudio.id'), where('userPrivilegeAdmin', '==', true)
            )
          )
        );
    
        this.cancellableCheck.promise.then((checkDoc) => {
          if(checkDoc.size){
            const docRef = doc(db, 'user', user.uid, 'userPrivilege', checkDoc.docs[0].id);

            let unsubscribe = onSnapshot(docRef, (doc) => {
              if(doc.data().userPrivilegeAdmin){
                this.setState({
                  signIn: true,
                  dataUser: {
                    uid: user.uid,
                    email: user.email
                  }
                });
              }else{
                unsubscribe();
                this.logOut();
              }
            }, (error) => {
              console.error(error);
    
              toast.error(() => (<>{error.code}<span>{error.message}</span></>));
              
              this.logOut();
            });
          }else{
            toast.error(() => (<>Opps...<span>Sorry, you are not admin.</span></>));

            this.logOut();
          }
        }).catch((error) => {
          console.error(error);
    
          toast.error(() => (<>{error.code}<span>{error.message}</span></>));
          
          this.logOut();
        });

        // this.setState({
        //   signIn: true,
        //   dataUser: {
        //     uid: user.uid,
        //     email: user.email
        //   }
        // });
      }else{
        this.setState({
          signIn: false,
          dataUser: {
            uid: '',
            email: ''
          }
        });
      }
    });
  }

  componentDidUpdate(prevProps, prevState){
    if(this.state.dataUser !== prevState.dataUser){
      if(typeof prevState.dataUser !== 'undefined'){
        if(this.state.dataUser.email !== prevState.dataUser.email){
          if(this.state.dataUser.email){
            toast.success(() => (<>Sign In Successfully<span>Hi <strong>{this.state.dataUser.email}</strong>!</span></>));
          }else{
            toast.success(() => (<>Sign Out Successfully<span>Bye!</span></>));
          }
        }
      }
    }
  }

  componentWillUnmount(){
    if(this.cancellableCheck){
      this.cancellableCheck.cancel();
    }
    
    if(this.cancellableLogout){
      this.cancellableLogout.cancel();
    }
  }

  logOut(){
    this.cancellableLogout = makeCancellablePromise(signOut(auth));

    this.cancellableLogout.promise.then(() => {
      this.setState({
        signIn: false,
        dataUser: {
          uid: '',
          email: ''
        }
      });
    }).catch((error) => {
      console.error(error);
      toast.error(() => (<>{error.code}<span>{error.message}</span></>));
    });
  }

  render(){
    if(this.state.signIn === ''){
      return(<LoadingPage text="Checking..." />);
    }else{
      return(
        <>
          <Suspense fallback={
            <LoadingPage text="Loading..." />
          }>
            <Routes>
              <Route path="/login" element={
                this.state.signIn ? (<Navigate to="/admin" />) : (<Login />)
              } />
              <Route path="/admin/*" element={
                this.state.signIn ? (<Dashboard dataUser={this.state.dataUser} logOut={this.logOut} />) : (<Navigate to="/login" />)
              } />
              <Route path="*" element={
                this.state.signIn ? (<Navigate to="/admin" />) : (<Navigate to="/login" />)
              } />
            </Routes>
          </Suspense>
        </>
      );
    }
  }
}

export default App;